import jQuery from "jquery"

Object.assign(window, { $: jQuery, jQuery })

import "chosen-js"
import "chosen-js/chosen.css"
import "react-onclickoutside"

import railsUjs from "@rails/ujs"
railsUjs.start()
